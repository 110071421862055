<template>
  <div class="card">
    <div class="card-header">
      <div class="utils__title">
        <strong>Články {{ getArticleTitle() }} - seznam</strong>
      </div>
      <action-tools :router-options="routerOptions" :handle-refresh="handleRefresh"></action-tools>
    </div>
    <div class="card-body">
      <div class="table-operations">
        <language-tab></language-tab>
      </div>
      <div class="row mb-3">
        <div class="col-md-6">
          <a-dropdown :placement="'bottomLeft'">
            <a-button>Hromadné akce <a-icon type="down"/></a-button>
            <a-menu slot="overlay">
              <a-menu-item :disabled="selectedRowKeys.length === 0" :class="{'disabled': selectedRowKeys.length === 0}">
                <a-popconfirm @confirm="confirmDelete(selectedRowKeys)" okText="Potvrdit" cancelText="Zrušit">
                  <template slot="title">
                    <p>Opravdu si přejete odstranit tyto položky?</p>
                    <div v-if="selectedRowKeys.length > 0">
                      {{ selectedRowKeys }}
                    </div>
                  </template>
                  Odstranit
                </a-popconfirm>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
        <div class="text-right col-md-6">
          <a-input-search
            placeholder="Hledat..."
            style="width: 200px"
            @search="handleSearch"
          />
        </div>
      </div>
      <a-table
        tableLayout="auto"
        :rowSelection="rowSelection"
        :rowKey="record => record.ate_id"
        :columns="columns"
        :loading="loading"
        :dataSource="articles"
        class="utils__scrollTable"
        :scroll="{ x: '100%' }"
      >
        <router-link
          slot="ate_id"
          slot-scope="value"
          class="utils__link--underlined"
          :to="'/article/type/' + ateType + '/detail/' + value"
        >#<span v-if="searchText">
            <template v-for="(fragment, i) in value.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))" >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
          <template v-else>{{value}}</template>
        </router-link>
        <template slot="customRender" slot-scope="text">
          <a-alert v-if="text === undefined" message="není nastaveno" type="warning" showIcon />
          <span v-else-if="searchText">
            <template v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))" >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
          <template v-else>{{text}}</template>
        </template>
        <span
          slot="active"
          slot-scope="value"
          :class="[value === undefined ? '' : value ? 'font-size-12 badge badge-success' : 'font-size-12 badge badge-danger']"
        >
          <a-alert v-if="value === undefined" message="není nastaveno" type="warning" showIcon />
        <span v-else-if="searchText">
            <template v-for="(fragment, i) in (value ? 'ano' : 'ne').toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))" >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
          <template v-else>{{value ? 'ano' : 'ne'}}</template>
        </span>
        <span
          slot="dates"
          slot-scope="text"
        >
          <a-alert v-if="text === undefined" message="není nastaveno" type="warning" showIcon />
        <span v-else-if="searchText">
            <template v-for="(fragment, i) in toLocaleDate(text).toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))" >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
          <template v-else>{{toLocaleDate(text)}}</template>
        </span>
        <span slot="action" slot-scope="record">
           <router-link :to="'/article/type/' + ateType + '/detail/'+record.ate_id">
            <a-button icon="search" size="small" class="mr-1">Zobrazit</a-button>
          </router-link>
          <a-popconfirm @confirm="confirmDelete([record.ate_id])" okText="Potvrdit" cancelText="Zrušit">
            <template slot="title">
                <p>Opravdu si přejete odstranit položku: #{{ record.ate_id }} ?</p>
            </template>
            <a-button icon="delete" size="small">Odstranit</a-button>
          </a-popconfirm>
        </span>
        <template slot="footer">
          {{ articles.length }} položek
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import LanguageTab from '@/components/MyComponents/LanguageTab'
import ActionTools from '@/components/MyComponents/ActionTools'
export default {
  components: { ActionTools, LanguageTab },
  data: function () {
    return {
      searchText: '',
      loading: false,
      ateType: this.$route.params.type,
      selectedRowKeys: [],
      routerOptions: [
        {
          icon: 'plus-circle',
          to: '/article/type/' + this.$route.params.type + '/add',
          title: 'Přidat',
        },
      ],
      columns: [
        {
          title: 'ID',
          dataIndex: 'ate_id',
          defaultSortOrder: 'ascend',
          sorter: (a, b) => a.ate_id - b.ate_id,
          scopedSlots: {
            customRender: 'ate_id',
          },
        },
        {
          title: 'Název',
          dataIndex: 'languages[0].pivot.ale_name',
          sorter: (a, b) => {
            return a.languages.length > 0 && b.languages.length > 0 ? a.languages[0].pivot.ale_name.localeCompare(b.languages[0].pivot.ale_name) : 0
          },
          scopedSlots: {
            customRender: 'customRender',
          },
        },
        {
          title: 'Vytvořeno',
          dataIndex: 'languages[0].pivot.created_at',
          sorter: (a, b) => {
            return a.languages.length > 0 && b.languages.length > 0 ? new Date(a.languages[0].pivot.created_at) - new Date(b.languages[0].pivot.created_at) : 0
          },
          scopedSlots: {
            customRender: 'dates',
          },
        },
        {
          title: 'Poslední úprava',
          dataIndex: 'languages[0].pivot.updated_at',
          sorter: (a, b) => {
            return a.languages.length > 0 && b.languages.length > 0 ? new Date(a.languages[0].pivot.updated_at) - new Date(b.languages[0].pivot.updated_at) : 0
          },
          scopedSlots: {
            customRender: 'dates',
          },
        },
        {
          title: 'Zveřejněno',
          dataIndex: 'languages[0].pivot.ale_is_active',
          sorter: (a, b) => {
            return a.languages.length > 0 && b.languages.length > 0 ? a.languages[0].pivot.ale_is_active - b.languages[0].pivot.ale_is_active : 0
          },
          scopedSlots: {
            customRender: 'active',
          },
        },
        {
          title: 'Akce',
          key: 'action',
          scopedSlots: {
            customRender: 'action',
          },
        },
      ],
    }
  },
  computed: {
    articles: function () {
      return this.$store.getters['article/currentLanguage'](this.searchText)
    },
    language: function () {
      return this.$store.getters['language/active']
    },
    rowSelection() {
      const { selectedRowKeys } = this
      return {
        selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        selections: [{
          key: 'all-data',
          text: 'Vybrat všechny položky',
          onSelect: () => {
            this.selectedRowKeys = this.selectedRowKeys.length === 0 ? this.articles.map(item => item.ate_id) : []
          },
        }],
        onSelection: this.onSelection,
      }
    },
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    handleRefresh() {
      this.loading = true
      this.$store.dispatch('article/getList', this.ateType)
        .finally(() => {
          this.loading = false
        })
    },
    confirmDelete(articles) {
      this.loading = true
      this.$store.dispatch('article/delete', articles)
        .then(() => {
          if (this.selectedRowKeys.length > 0) {
            this.selectedRowKeys = articles.filter(x => !this.selectedRowKeys.includes(x))
              .concat(this.selectedRowKeys.filter(x => !articles.includes(x)))
          }
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    handleSearch(searchText) {
      this.searchText = searchText
    },
    toLocaleDate: function (text) {
      return this.$options.filters.formatDate(text)
    },
    getArticleTitle() {
      const type = this.$store.getters['article/getTypes'](this.ateType)
      return type && type.title ? ' - ' + type.title : ''
    },
  },
  created() {
    if (this.language !== null) {
      this.handleRefresh()
    }
  },
  watch: {
    language(newValue, oldValue) {
      if (newValue !== null && this.articles.length === 0) {
        this.handleRefresh()
      }
    },
    '$route.params.type'(newValue, oldValue) {
      this.ateType = newValue
      this.$store.state.article.items = []
      if (newValue !== null && this.language !== null) {
        this.handleRefresh()
      }
    },
  },
}
</script>

<style lang="scss" module scoped>

</style>
